export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";

export const REG_REQUEST = "REG_REQUEST";
export const REG_SUCCESS = "REG_SUCCESS";
export const REG_FAILURE = "REG_FAILURE";

export const RESTORE_AUTH = "RESTORE_AUTH"

export const RESET_PASSOWRD_REQUEST = "RESET_PASSWORD_REQUEST";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAILURE = "RESET_PASWWORD_FAILURE";

export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";


export const TRANSACTION_DETAILS_REQUEST = "TRANSACTION_DETAILS_REQUEST"
export const TRANSACTION_DETAILS_SUCCESS = "TRANSACTION_DETAILS_SUCCESS"
export const TRANSACTION_DETAILS_FAILURE = "TRANSACTION_DETAILS_FAILURE"

export const GET_USER_REQUEST = "GET_USER_REQUEST"
export const GET_USER_SUCCESS = "GET_USER_SUCCESS"
export const GET_USER_FAILURE = "GET_USER_FAILURE"


export const CHANGE_PASSWORD_REQUEST = "CHANGE_PASSWORD_REQUEST"
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS"
export const CHANGE_PASSWORD_FAIURE = "CHANGE_PASSWORD_FAILURE"